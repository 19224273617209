import 'matchmedia-polyfill';
import React from "react";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import { AppProvider } from "./context/AppContext";

import { render } from "react-snapshot";




// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <AppProvider>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </AppProvider>
// );





render(
  <AppProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </AppProvider>,
  document.getElementById('root')
);
reportWebVitals();